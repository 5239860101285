@import '@/styles/vendors/reveal.css';
@import '@/styles/animations.css';
@import '@/styles/app.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: Roboto, Helvetica, Arial, sans-serif;
    }

    body {
        background-color: #000000;
    }

    html, body {
        height: 100%;
        width: 100%;
        height: 100vh;
        width: 100vw;
        margin: 0;
        padding: 0;
    }

    .reveal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 0;
        margin: 0;
        z-index: 1;
    }

    h1, h2 {
        font-family: Ubuntu, Helvetica, Arial, sans-serif;
        font-weight: 700;
    }

    a {
        text-decoration: underline;
    }

    section {
        display: flex !important;
    }
}

@layer components {
    .sbs-button {
        @apply py-2.5 px-5 sm:px-8 rounded-[40px] h-[40px] sm:h-[48px] font-ubuntu disabled:!opacity-30 disabled:cursor-not-allowed !text-lg;
    }

    .sbs-button-primary {
        @apply bg-dark-tangerine hover:bg-golden-tainoi text-black;
    }

    .sbs-button-secondary {
        @apply bg-black hover:bg-grey text-white;
    }

    .sbs-button-small {
        @apply py-[4px] px-[14px] h-[36.5px] text-[14px]/[1.75] min-w-[110px] font-bold leading-7 tracking-wide;
    }

    .rewind-slide-title {
        @apply text-2xl lg:text-3xl 2xl:text-4xl;
    }

    .rewind-slide-solo-title {
        @apply text-3xl lg:text-3xl 2xl:text-4xl;
    }
}

@layer base {
    html {
        -webkit-tap-highlight-color: transparent;
    }
}
