@CHARSET "ISO-8859-1";

body {
    background: linear-gradient(-38deg, #0B0D0F, #303940, #0B0D0F, #303940);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    height: 100vh;

    @media (prefers-reduced-motion) {
        animation: gradient 75s ease infinite;
    }
}

/** Resets some default CSS set by Tailwind */
.sbs-login-container svg {
    display: initial;
    vertical-align: initial;
}

.reveal button,
.reveal .button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Ubuntu, Helvetica, Arial, sans-serif;
    font-size: 1.125rem;
    font-weight: 700;
}

@media screen and (max-width: 1279px) {
    .reveal button,
    .reveal .button {
        font-size: 1rem;
    }
}

.reveal a.button {
    text-decoration: none;
}

.reveal:not(.eligible) .progress {
    display: none !important;
}

.reveal.eligible .progress {
    top: 60px;
    bottom: auto;
    background-color: theme('colors.grey');

    & span {
        background-color: theme('colors.dark-tangerine');
    }
}

.reveal img {
    max-width: initial;
    max-height: initial;
    height: inherit;
    margin: 0;
}

.section img {
    display: inline-block;
}

/** Override display of section to allow opacity transition */
.reveal .slides>section,
.reveal .slides>section>section {
    display: block;
}

/** Page transition tweaks */
.loading .section,
.past .section,
.future .section {
    opacity: 0;
}

.present .section {
    opacity: 1;
}

section.vertical-section {
    flex-direction: column;
    position: relative !important;
    justify-content: center;
    align-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.sbs-login-content .sbs-login-header h1 {
    color: black;

    @media screen and (max-width: 719px) {
        font-size: 1.125rem;
    }
}

.sbs-login-container {
    max-width: 97vw;
}

/* There is a bug in SBS Login SDK, when choosing to hide the language switcher, the container is still showing */
.sbs-login-lang-switcher {
    display: none !important;
}

.reveal section section {
    scale: var(--page-scale-ratio);
}

.reveal section section#countries {
    scale: 1;
}

@media screen and (max-height: 530px) and (orientation:landscape) {
    .navbar, .reveal {
      display: none;
    }

    .portrait-only {
        display: flex !important;
    }

    #login-modal {
        display: none;
    }
}

@media screen and (max-width: 320px) {
    .login-modal div {
        height: auto;
    }

    .sbs-login-content .sbs-login-header h1 {
        margin-top: 5px;
    }
}

@media screen and (max-width: 719px) {
    .scene-tooltip {
        left: 0 !important;
        bottom: 10px !important;
        transform: translateX(0px) !important;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}


