.slide-up,
.slide-down,
.slide-in-from-left,
.slide-in-from-right {
    transition: all 0.6s ease-in-out, background-color 0s;
    position: relative;
}

.fade-in,
.fade-out {
    transition: opacity 0.6s ease-in-out;
}

/** delay to give time for the previous page unload animation to complete */
.ready .section > *:not([class*="delay-"]) {
    transition-delay: 0.4s;
}

.ready .no-delay .slide-up,
.ready .no-delay .slide-down,
.ready .no-delay .slide-in-from-left,
.ready .no-delay .slide-in-from-right,
.ready .no-delay .fade-in,
.ready .no-delay .fade-out {
    transition-delay: 0s !important;
}

/** fade-in */
.loading .fade-in,
.future .fade-in {
    opacity: 0 !important;
}

.fade-in {
    opacity: 0;
}

/** fade-out */
.loading .fade-out,
.future .fade-out {
    opacity: 1 !important;
}

.fade-out {
    opacity: 1;
}


/** slide-in-from-left */
.loading .slide-in-from-left,
.future .slide-in-from-left {
    opacity: 0 !important;
    transform: translateX(-100px) !important;
}

.slide-in-from-left {
    opacity: 0;
    transform: translateX(-100px);
}

/** slide-in-from-right */
.loading .slide-in-from-right,
.future .slide-in-from-right {
    opacity: 0 !important;
    transform: translateX(100px) !important;
}

.slide-in-from-right {
    opacity: 0;
    transform: translateX(100px);
}

/** slide-down */
.loading .slide-down,
.future .slide-down {
    opacity: 0 !important;
    transform: translateY(-35px) !important;
}

.slide-down {
    opacity: 0;
    transform: translateY(-35px);
}

/** slide-up */
.loading .slide-up,
.future .slide-up {
    opacity: 0 !important;
    transform: translateY(25px) !important;
}
.slide-up {
    opacity: 0;
    transform: translateY(25px);
}

/** reset transform for all when slide is visible */
.present .present .slide-in-from-left,
.present .present .slide-in-from-right,
.present .present .slide-down,
.present .present .slide-up {
    transform: translate(0, 0);
    opacity: 1;
}

.present .present .fade-in {
    opacity: 1;
}

.present .present .fade-out {
    opacity: 0;
}

/** Dummy section */
.section.dummy h1 {
    transform: scale(0.5);
}

.present .present .section.dummy h1 {
    transform: scale(1);
}

/* Menus */
.fade-in-on-hover {
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
